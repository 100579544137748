.search_password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89vh;
  overflow: hidden;
}

.search_password-box {
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background: rgba(187, 68, 228, 0.16);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  overflow: hidden;
  padding: 40px;

}

/* .search_id-container {
  background-color: #F8F6F8;
} */

.num_p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.numpass,
.emailpass,
.passtoken,
.passnew {
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.sendemail-button {
  border-radius: 20px;
  width: 75px;
  height: 105px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  border: none;
  background-color: black;
  margin-left: 5px;
  margin-bottom: 20px;
}

.passtokencon {
  margin-top: 10px;
  border-radius: 40px;
  width: 280px;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  border: none;
  background-color: black;
  margin-bottom: 20px;
}

.psword_gotochange {
  margin-top: 10px;
  border-radius: 40px;
  width: 280px;
  height: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  border: none;
  background-color: black;
}

.sendemail-button:hover,
.passtokencon:hover,
.psword_gotochange:hover {
  background-color: white;
  color: #bb44e3;
  border: none;
}

.pass_number {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

.pass_number::-webkit-outer-spin-button,
.pass_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spin {
  margin-left: 15px;
  width: 22px;
  height: 22px;
  color: #bb44e3;
}

.loading-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-text{
  margin-left: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #bb44e3;
}