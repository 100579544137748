.default-container {
  display: flex;
}

.content {
  flex: 1;
  padding: 20px;
}

.introduction-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.introduction-box {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.introduction-box h2 {
  cursor: pointer;
  transition: color 0.3s;
}

.introduction-box h2:hover {
  color: #bb44e4;
}

.introduction-box h2:active {
  color: #bb44e4;
}

.introduction-box.represent {
  background-color: rgba(187, 68, 228, 0.16);
  border: 2px solid #bb44e4;
}
