.stack-container {
  box-sizing: border-box;
  width: 90%;
  max-width: 665px;
  height: 630px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-bottom: 1%;
}

.selected-stack-box {
  box-sizing: border-box;
  width: 85%;
  height: 13%;
  background: rgba(187, 68, 228, 0.16);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: 3%;
  border: 1px solid #b5b5b5;
  position: relative;
  z-index: 1;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  scroll-padding: 10px;
}

.input-box {
  box-sizing: border-box;
  width: 85%;
  height: 8%;
  min-height: 50px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 45px;
  padding-left: 3%;
  margin-top: 3%;
  border: 1px solid #b5b5b5;
}

.custom-box {
  box-sizing: border-box;
  position: absolute;
  width: 85%;
  height: 50%;
  background: #f2f2f2;
  border: 1px solid #b5b5b5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-bottom: 2%;
  position: relative;
  z-index: 0;
  padding: 1%;
  overflow-y: auto;
}

.next-button {
  position: relative;
  padding: 0 20px;
  height: auto;
  min-height: 120%;
  margin-top: 10%;
  margin-bottom: 2%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.next-button:hover {
  background-color: #bb44e4;
  color: white;
  border: none;
}

.stack-heading {
  width: 85%;
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

.selected-stack {
  display: inline-block;
  margin-right: 1%;
  background-color: #f0f0f0;
  padding: 1% 2%;
  border-radius: 5px;
}

.adjustable-font-size {
  font-size: 1.7em; /* 기본 폰트 크기 */
}

@media (max-width: 768px) {
  .adjustable-font-size {
    font-size: 1.2em; /* 작은 화면에 대한 폰트 크기 */
  }
}

@media (max-width: 480px) {
  .adjustable-font-size {
    font-size: 1em; /* 더 작은 화면에 대한 폰트 크기 */
  }
}
