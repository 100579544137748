.search_id {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
    overflow: hidden;
}


.search_id-box {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    background: rgba(187, 68, 228, 0.16);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    overflow: hidden;
    padding: 30px;
}

.sid,
.email-result {
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.searchid-button,
.gotolog {
    margin-top: 20px;
    border-radius: 40px;
    width: 280px;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border: none;
    background-color: black;
}

.searchid-button.searchid-button:hover,
.gotolog.gotolog:hover {
    background-color:  white;
    color: #bb44e3;
    border: none;
}

.input_Number {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }
  
  .input_Number::-webkit-outer-spin-button,
  .input_Number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }