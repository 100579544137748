.myedit_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* justify-content: space-between; */
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.infomodify {
    font-weight: bold;
    /* text-align: right; */
    position: absolute;
    right: 0;
    top: -30px;
    margin-right: 15px;
}

.edit_profile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 1000px;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.edit_field {
    align-items: center;
    text-align: left;
    font-size: 20px;
    display: inline-block;
    width: 300px;
    /* margin-bottom: 20px; */
}

.edit_field>* {
    margin-bottom: 20px;
}

/* 
.modifybtn{
    background-color: #000;
    color: #fff;

}

.modifybtn:hover {
    background-color: #fff;
    color: #bb44e4;
} */