/* recruit.css */

.recruit-container {
  width: 100%;
  height: 90vh;
  display: flex;
  background-color: rgb(253, 253, 253);
  overflow:auto;
}

.sidebar-container {
  flex-basis: 420px;
  padding-left: 80px;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.grid-container {
  flex-grow: 1;
  display: grid;
  gap: 10px;
  margin-right: 40px;
  grid-auto-rows: 1fr;
  margin-top: 40px;
}

.legend-grid-container {
  flex-grow: 1;
  display: grid;
  gap: 10px;
  margin-right: 40px;
  grid-auto-rows: 1fr;
}

.pagination span a.active {
  font-weight: bold;
  color: #bb44e4;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.pagination button {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  box-shadow: none;
  padding: 8px 12px;
  font-weight: bold;
}

.pagination button:hover {
  background-color: #bb44e4;
  color: #ffffff;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #cccccc;
  color: #ffffff;
}

.pagination-link {
  display: inline-block;
  width: 2em;
  text-align: center;
  text-decoration: none;
  color: #000000;
}

.pagination-link:hover {
  color: #9e74d6;
}

.search-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 90px;
}

.search-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: auto;
  min-width: 260px;
}

.search-container button {
  position: flex;
  width: 89px;
  height: 36px;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  color: #ffffff;
}

.search-result-message {
  font-size: 120%;
  visibility: hidden;
  margin-top: 0;
}

.search-result-message.show {
  visibility: visible;
  margin-top: 100px;
}

/* ...기타 스타일... */
.recommend-label {
  margin-top: 30px;
  margin-left: 50px;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.recruit-label {
  margin-top: 30px;
  margin-left: 70px;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}