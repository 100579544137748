.result-header {
  font-size: 40px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.chart-header {
  font-size: 25px;
  font-weight: bold;
  color: rgb(249, 153, 169);
  text-align: center;
  position: absolute;
  top: 0px;
  margin-top: 90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; /* 네비게이션 바가 위로 오도록 설정 */
}

.chart-container {
  width: 1200px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;  
  border-radius: 30px;
  padding: 60px 20px 20px 20px;
  position: relative; /* 상대 위치 설정 */
  z-index: 0; /* 네비게이션 바가 위로 오도록 설정 */
}

.chart-wrapper {
  width: 50%;
  height: 70%;
  margin-right: 20px;
  background-color: rgba(187, 68, 228, 0.16);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.rank-container {
  width: 35%;
  height: 70%;
  background-color: rgba(187, 68, 228, 0.16);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;
  font-size: 25px;
  font-weight: bold;
}

.rank-item {
  margin-bottom: 25px;
}

.recommand-button {
  display: block;
  width: 200px;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-footer {
  position: absolute;
  bottom: 0;
  font-size: 18px;
  color: gray;
  width: 1000px;
  text-align: center;
  margin-bottom: 70px;
}

.next-button:hover {
  background-color: #bb44e4;
  color: white;
  border: none;
}

.result-default-container {
  background-size: cover;
  background-position: center;
  background-color: rgb(253, 253, 253);
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow: auto;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .result-header {
    font-size: 30px;
    left: 25%;
    margin-bottom: 0px;
    position: relative;
    top: 40%;
  }

  .chart-header {
    font-size: 20px;
    left: 32%;
    margin-top: 0px;
    position: relative;
    top: 41%;
    margin-bottom: 140px;
  }

  .chart-wrapper {
    margin: 20px 0;
    width: 100%;
    height: 100%;
  }

  .chart-container {
    flex-direction: column;
    width: 400px;
    height: 500px;
  }

  .rank-container {
    font-size: 18px;
    padding-left: 50px;
    padding-top: 60px;
    width: 370px;
    height: 250px;
  }

  .rank-item {  
    margin-bottom: 15px;
  }

  .recommand-button {
    width: 165px;           /* 150px * 1.1 */
    height: 41.25px;       /* 37.5px * 1.1 */
    line-height: 41.25px;  /* 37.5px * 1.1 */
    font-size: 16.5px;     /* 15px * 1.1 */
    margin-top: 22px;      /* 20px * 1.1 */
    position: relative;
    transform: none;
    left: 0%;
    top: 10%;
    margin-bottom: 22px;   /* 20px * 1.1 */
}

  .chart-footer {
    margin-bottom: 0px;
    font-size: 15px;
    position: relative;
    top: 180%;
  }

  .result-default-container{
    margin-bottom: 20px;
  }
}
