.introduce-container {
  box-sizing: border-box;
  width: 90%;
  max-width: 665px;  
  height: 600px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 30%;
}

.ex {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
}

.previous-button {
  position: absolute;
  width: 80px;
  height: 37px;
  margin-top: 10px;
  right: 190px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.previous-button:hover,
.result-button:hover {
  background-color: #bb44e4;
  color: white;
  border: none;
}

.result-button {
  position: absolute;
  width: 130px;
  height: 37px;
  margin-top: 10px;
  right: 50px;
  background-color: #000000;
  color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.FloatingLabel {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  position: relative;
  z-index: 0;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  z-index: 10000;
}

.loading-content span {
  margin-bottom: 10px;
}

.loading-content img {
  width: 225px;
  height: 225px;
}

.max-length {
  display: inline-block;
  text-align: right;
  margin-left: 190px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .max-length {
    margin-left: 240px;
    margin-top: 0px;
  }

  .previous-button {
    right: 210px;
    margin-top: 0px;
  }

  .result-button {
    right: 70px;
    margin-top: 0px;
  }
}