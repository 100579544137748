  .log_in {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 89vh;
    overflow: hidden;
  }


  .logform-box {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
    background: rgba(187, 68, 228, 0.16);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    overflow: hidden;
    padding: 40px;
  }

  /* .search_id-container {
    background-color: #F8F6F8;
  } */

  .sign_in,
  .ppsin {
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .signin-button {
    margin-top: 20px;
    border-radius: 40px;
    width: 280px;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border: none;
    background-color: black;
  }

  .signin-button.signin-button:hover {
    background-color: white;
    color: #bb44e3;
    border: none;
  }

  .links {
    display: flex;
    justify-content: center;
    margin-top: 20px;

  }

  .links p {
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
    color: black;
    font-size: 12.5px;
    text-decoration: underline;
  }

  .links p:hover {
    color: #bb44e3;
    text-decoration: underline;
  }