.myedit_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* justify-content: space-between; */
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.userinfo {
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -30px;
    margin-right: 15px;
}

.p_profile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 1000px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.text-profile {
    align-items: center;
    text-align: left;
    font-size: 20px;
    display: inline-block;
    width: 300px;
}

.text-profile p {
    margin-bottom: 40px;
}

.text-profile strong {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}